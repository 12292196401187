@import "common.css";

.heading2 {
	font-weight: 100;
	margin: 0;
	font-size: 30px;
}

.form-horizontal .control-label {
	text-align: left;
	margin-bottom: 8px;
}

.pad30 {
	padding: 30px 0;
}

.kontact-frame {
	position: relative;
	height: 500px;
	overflow: hidden;
	margin-top: 100px;

	@media (max-width: 767px) {
		margin-top: 80px;
	}

	@media (max-width: 550px) {
		margin-top: 50px;
	}

	>div {
		height: 100%;
	}

	.mapnote {
		margin: 0;
		font-size: 16px;
		padding: 15px;

		@media (max-width: 767px) {
			font-size: 12px;
			min-height: 84px;
		}

		a {
			color: var(--primarycolor);

			&:hover {
				color: var(--black);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 30px 15px 15px;
		height: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: column;
		text-align: center;

		.doc-box {
			.body {
				text-align: left;
			}
		}

		.footer-link {
			margin: 0 0 0 0 !important;
		}

		>a,
		.footer-link {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 0px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;
			text-transform: uppercase;
			letter-spacing: 2px;
			text-decoration: none !important;

			&:hover {
				background: var(--secondarycolor);
			}
		}

		.doc-box {
			.body {
				a {
					color: var(--primarycolor);

					&:hover {
						color: var(--secondarycolor);
					}
				}
			}
		}
	}

	.contact-map {
		width: 100%;
		height: 425px;
		position: static;

		@media (max-width:767px) {
			top: 40px;
			height: 420px;
		}
	}
}

p {
	color: var(--grey2);
	line-height: 1.5;
}

.mb-20 {
	margin-bottom: 20px;
}



.address-dt {
	//text-transform: uppercase;

	span {
		color: var(--secondarycolor);
		margin-bottom: 5px;
		display: block;
	}



	p {
		margin-bottom: 30px;
		color: var(--blackcolor);

		a {
			color: var(--blackcolor);
		}
	}
}

.inner-banner {

	width: 100%;
	background-position: center;
	background-size: cover;
	background-position: no-repeat;
	/* background-image: url(RESOURCE/img/bg-banner-new4.jpg); */
	background-image: url(RESOURCE/img/baltic-sea.jpg);

	.page-title {
		display: flex;
		align-items: center;
		height: 500px;
	}

	&.banner-info {
		background-image: url(RESOURCE/img/kleinerkaiser-info.jpg);
	}
}

.info-contact {
	margin-bottom: 20px;
	width: 100%;

	@media (min-width:768px) {
		max-width: 400px;
		float: right;
		margin-left: 15px;
	}

	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;

		a {
			color: var(--primarycolor);

			&:hover {
				color: var(--secondarycolor);
			}
		}
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 30px 15px 15px 15px;

		p {
			font-size: 14px;
			margin: 0 0 20px 0;
		}

		>a,
		.footer-link {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 0px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondarycolor);
			}
		}

		.doc-box {
			.body {
				a {
					color: var(--primarycolor);

					&:hover {
						color: var(--secondarycolor);
					}
				}
			}
		}
	}
}