.booking-view {

	.cart-details {
		background-color: var(--light-grey);
		padding: 15px;
		margin-top: 35px;

		.btn {
			display: none;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;
			padding-left: 0;

			li {
				margin-left: 0px;
				list-style: none;
				padding-left: 25px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: fontawesome;
					position: absolute;
					left: 0;
					color: var(--primarycolor);
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


}