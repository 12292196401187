:root {
	--introImg: url(RESOURCE/img/bg-banner-new.jpg);
	--introMobileImg: url(RESOURCE/img/banner-banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--whitecolor: #fff;
	--primarycolor: #BC0C74;
	--defaultfont: Roboto, sans-serif;
	--greycolor: #a0927e;
	--secondarycolor: #A69383;
	--infocolor: #ede6e0;
	--light-grey: #f9f9f9;
	--blackcolor: #455565;
	--grey2: #7f8a95;
	--cal_primary: #cd7898;
}