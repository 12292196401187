@import "setup.css";

#homenavbar {
	.header {
		&.affix-top {
			top: 35px;
			background: rgba(255, 255, 255, .5) !important;
		}

	}

	.header {
		position: fixed;
		z-index: 2;

		&.affix {
			top: 0;
			background: rgba(255, 255, 255, 1) !important;
		}
	}
}

.header {
	&.affix-top {
		background: rgba(255, 255, 255, 1) !important;
	}

	&.affix {
		background: rgba(255, 255, 255, 1) !important;
	}

}




header {
	position: fixed;
	z-index: 5;
	width: 100%;
	//background: rgba(255, 255, 255, 0);
	margint-top: 20px;
	margin-top: 0;
	height: 80px;
	display: flex;
	top: 0;

	@media (max-width: 550px) {
		height: 40px;
	}

	.logo {
		display: inline-block;
		max-width: 350px;

		@media (max-width:1340px) {
			max-width: 230px;
		}

		@media (max-width:550px) {
			max-width: 180px;
		}
	}

	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 80px;

		@media (max-width: 550px) {
			height: 40px;
		}

		.navbar-area {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
			}

			@media (max-width:992px) {
				position: absolute;
				display: none;
				width: 100%;
				background-color: #fff;
				left: 0;
			}

			padding: 0;
			margin: 0;

			li {
				list-style: none;

				a {
					color: var(--secondarycolor);

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}


	.nav-contact {
		@media (max-width:992px) {
			display: flex;
		}

		a {
			margin-right: 15px;

			@media (max-width:992px) {
				margin-left: 3px;
				margin-right: 3px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.fa {
			font-size: 13px;
			width: 27px;
			height: 27px;
			background: var(--primarycolor);
			color: #fff;
			border-radius: 100%;
			text-align: center;
			line-height: 26px;

			margin-right: 4px;

			@media (max-width:550px) {
				font-size: 12px;
				width: 20px;
				height: 20px;

				line-height: 20px;
			}
		}


		span {
			padding: 17px 2px;
			color: var(--blackcolor);
			font-size: 12px;
			text-transform: uppercase;

			@media (max-width:1170px) {
				display: none;
			}
		}


		@media (max-width:992px) {
			position: absolute;
			top: 29px;
			right: 24px;
		}

		@media (max-width: 550px) {
			top: 9px;
		}
	}
}


.vo-nav-bar {

	a.nav-item {
		font-size: 13px;
		/*font-weight: 500;*/
		letter-spacing: .05em;
		text-transform: uppercase;
		padding: 20px 7px;
		color: var(--secondarycolor);

		@media (max-width:992px) {
			padding: 10px;
		}

		&:hover {
			color: var(--primarycolor);
			text-decoration: none;
		}
	}


	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown {
		padding-right: 10px;

		&:hover {
			.dropbtn {
				color: var(--primarycolor);
			}

		}
	}


	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: var(--primarycolor);
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 8px 16px;
		text-decoration: none;
		color: var(--whitecolor);
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		text-align: left;
	}



	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {


	.vo-nav-bar.opened .nav-item {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
		position: relative;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			display: block;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 100%;
		}
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		position: absolute;
		right: 10px;
		top: 10px;
		display: block;
	}

	.vo-nav-bar .vo-dropdown:hover .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar .vo-dropdown:hover .vo-dropdown-content a {
		display: block !important;
	}
}