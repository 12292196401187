@import "setup.css";

.facility-view {
	.title-img {
		@media (max-width:767px) {
			height: 500px !important;
		}
	}

	.GC {
		background-position: 50% 100% !important;
	}

	.Haff_22 {
		background-position: 50% 32% !important;
	}

	.Kliff {
		background-position: 50% 85% !important;
	}

	.imperial-court-block {
		padding: 30px 15px;
		background: url(../img/imperial-court-bg.jpg) no-repeat center top;
		background-size: cover;

		h2 {
			color: rgba(255, 255, 255, 0.8);
			font-weight: 100;
			margin: 0;
			font-size: 30px;
		}

		.text {
			width: 75%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		p {
			color: rgba(255, 255, 255, 0.8);
			margin-top: 20px;
			font-size: 16px;
		}
	}

	.accommodations-block {
		padding: 30px 15px;
		background-color: #ebebeb;

		.row {
			padding: 0 !important;

			.col-md-4 {
				margin-top: 25px;
			}

			@media (min-width:992px) {
				.col-md-4:nth-child(3n+1) {
					clear: both;
				}
			}

			@media all and (max-width: 991px) and (min-width: 768px) {
				.col-md-4:nth-child(2n+1) {
					clear: both;
				}
			}

		}

		h2 {
			font-weight: 100;
			margin: 0;
			font-size: 30px;
		}

		.accommodations-detail {
			a {
				&:hover {
					text-decoration: none;
				}
			}

			img {
				width: 100%;
			}

			h3 {
				background-color: #7C96AE;
				margin: 0;
				color: var(--whitecolor);
				font-weight: 400;
				text-transform: uppercase;
				font-size: 13px;
				text-align: center;
				padding: 10px 0;
			}

			.accommodationsDesc {
				background-color: var(--whitecolor);
				padding: 15px 10px;


				p {
					margin: 0;
					color: #7F8A95;
					font-size: 13px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			.accommodationsIcon {
				background-color: #7C96AE;
				text-align: center;
				padding: 8px 0;

				span {
					background: #475E6F;
					color: #fff;
					padding: 3px 10px;
					display: inline-block;
					vertical-align: top;
					font-size: 11px;
					min-height: 23px;
					line-height: 17px;

					i {
						font-size: 11px;
						margin-right: 3px;
					}
				}
			}

			.inqBtn {
				background-color: #BC0C74;
				display: block;
				text-align: center;
				color: #fff;
				font-size: 11px;
				padding: 8px 15px;
				line-height: 17px;
			}
		}

	}
}