@import "common.css";

.region-view {
	.heading2 {
		font-weight: 100;
		margin: 0;
		font-size: 30px;
	}

	.form-horizontal .control-label {
		text-align: left;
		margin-bottom: 8px;
	}

	.pad30 {
		padding: 30px 0;
	}

	p {
		color: var(--grey2);
		line-height: 1.5;
	}

	h1 {

		font-weight: 100;
		font-size: 30px;
	}
}