@import "common.css";

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.unit-banner {
		position: relative;

		.page-header {
			position: absolute;
			bottom: 30px;
			width: 100%;
			left: 0;
			z-index: 2;
			margin: 0;
			border-bottom: none;

			h1 {
				font-size: 36px;
				margin-bottom: 0;
				color: var(--whitecolor);
				text-transform: capitalize;
			}

			p {
				color: var(--whitecolor);
				margin-top: 8px;
			}

			.breadcrumb {
				padding: 30px 0;

				.breadcrumb-item {
					a {
						color: var(--white);
					}
				}
			}
		}

		&:after {
			background-color: rgba(70, 86, 102, .5);
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			z-index: 1;
		}
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

		.asd__wrapper {
			z-index: 9;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 500px;
		margin-bottom: 0px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		max-width: 1200px;

		h2 {
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0 20px;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding: 0;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 10px;
				margin: 5px 0;

				.day {
					width: 24px;
					height: 24px;
					display: inline-block;
					margin: 0 10px 0 0;
					border: 1px solid #e4e7e7;

					&.day-free {
						background: var(--whitecolor);
					}

					&.day-full {
						background: var(--cal_primary);
					}

					&.day-start {
						background: var(--cal_primary);
						background: linear-gradient(90deg, var(--cal_primary) 20%, #fff 30%);
					}

					&.day-end {
						background: var(--whitecolor);
						background: linear-gradient(90deg, #fff 70%, var(--cal_primary) 80%) !important;
					}
				}
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 0 0 15px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				margin-left: 15px;
				margin-right: 15px;
				@mixin columnlayout 2;

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.book-side-form {
			border: 2px solid var(--primarycolor);
		}

		.info-col {
			.info-col-header {
				padding: 10px;
				background-color: var(--blackcolor);
				color: var(--whitecolor);
				text-align: center;

				h2 {
					margin-top: 10px;
					font-size: 22px;
					color: var(--whitecolor);
					line-height: 1.3;
					margin: 0;
				}

				i {
					margin-right: 5px;
				}
			}

			.subprice {
				width: 100%;

			}

			.btn-border {
				border: 1px solid #888;
				padding: 10px;
				display: block;
				text-align: center;
				width: 100%;
				background-color: var(--white);

				&.text-prim {
					color: var(--primarycolor);
					margin-top: 5px;
				}
			}

		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px 15px 1px 15px;

				.search-section {
					flex-wrap: wrap;


					.searchbox {
						width: 100%;
						flex: unset;
						margin: 5px 0;
					}
				}
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}

	.well {
		i {
			marign-right: 5px;
			color: var(--grey2);
		}

		a {
			color: var(--grey2);

			&:hover {
				color: var(--primarycolor);
			}
		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
			margin-bottom: 30px;
			padding: 0 0 115px 0;

			@media (max-width: 767px) {
				padding: 0 0 70px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				padding: 0 0 85px;
			}

			>div {
				height: 100%;
			}

			&.no-map {
				padding: 0 0 0 0;

				.mapconsent {
					background: rgba(26, 121, 187, .15);
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
					flex-direction: column;
					text-align: center;
					font-size: 16px;

					p {
						margin: 0 0 20px;
						font-size: 16px;
						padding: 0 15px;
					}

					.doc-box {
						.box {
							text-align: left;
						}
					}

					>a,
					.mapconsent-button {
						background: var(--primarycolor);
						color: #fff;
						border-radius: 0;
						padding: 0 15px;
						height: 35px;
						display: inline-block;
						line-height: 35px;
						text-decoration: none !important;
						font-size: 14px;
						text-transform: uppercase;
						letter-spacing: 2px;

						&:hover {
							background: var(--secondarycolor);
						}
					}
				}

			}

			.mapnote {
				margin: 0 0 15px 0;

				@media (max-width: 767px) {
					font-size: 12px;
					min-height: 56px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					min-height: 70px;
				}

				a {
					color: var(--primarycolor);

					&:hover {
						color: var(--secondarycolor);
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.unit-feat-icons {
		i {
			color: var(--primarycolor);
			height: 30px;

			&.i-door {
				background-image: url(RESOURCE/img/door-new.png);
			}
		}

		span {
			font-size: 12px;
			display: block;
			margin-top: 5px;
			margin-bottom: 15px;

			div {
				display: inline-block;
			}
		}
	}

	.contact-form {
		border: 1px solid #eee;
		padding: 0 10px 10px;

		.form-control {
			letter-spacing: normal;
			text-transform: none;
			font-size: 14px;
		}
	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	.more-trigger {
		margin-bottom: 10px;

		&:after {
			content: "\f067";
			font-family: fontawesome;
			margin-left: 3px;
		}

		&.show {
			&:after {
				content: "\f068";
			}
		}
	}



	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	&.owl-carousel {
		.owl-item {
			height: 550px;
			text-align: center;
			background-color: #000;
			display: flex;
			align-items: center;

			@media (max-width:767px) {
				height: 400px;
			}

			img {
				width: auto;
				display: inline-block;
				height: 590px;
				object-fit: cover;

				@media (max-width:767px) {
					height: 400px;
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 40%;
			width: 100%;

			button {
				color: #fff !important;
				text-shadow: 1px 1px 1px #000;
				position: absolute;
				z-index: 999;
				background: transparent;
				border: 0;
				font-size: 65px !important;
				padding: 0 10px 10px !important;
				background: rgba(0, 0, 0, .4) !important;
				line-height: 1 !important;

				&.owl-next {
					right: 0;
				}
			}
		}
	}

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}


	}

	.owl-nav {
		&.disabled {
			display: block !important;
		}
	}

}

.unit-proposals {
	background-color: #f8f8f8;
}

.unit-prp {
	.list-of-ausstattung {
		margin-left: -15px;
		margin-right: -15px;

		.col-md-4 {
			margin: 0 0 30px 0;
		}
	}

	.col-md-4 {
		&:nth-child(3n+1) {
			clear: both;
		}
	}
}

.asd__wrapper--full-screen {
	Z-index: 9;
}