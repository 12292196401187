.main-footer {

	.contact-section {
		padding: 40px 10px 80px 10px;
		text-align: center;

		p {
			color: #555;
			margin-bottom: 20px;
		}

		p.lead {
			font-size: 24px;
			margin-bottom: 10px;
		}

		.contact {
			font-size: 24px;

			.fa {
				margin: 10px;
				font-size: 36px;
			}



			color: @introcolor;
		}
	}


	footer {
		width: 100%;
		padding: 20px;
		text-align: center;
		background-color: var(--infocolor);
		text-align: left;

		.company-name,
		.footer-link {
			font-family: "Raleway", sans-serif;

			font-size: 18px;

			background-color: @navbgcolor;

			color: #888;


		}

		.no-style {
			list-style: none;
			padding: 0;
			margin: 0 0 15px;

			li {
				padding: 5px 0;

				.fa {
					color: var(--secondarycolor);
					margin-right: 10px;
				}
			}
		}

		.icon-style {
			list-style: none;
			padding: 0;
			margin: 0 0 15px;

			li {
				padding: 5px 0;
				position: relative;
				padding-left: 25px;

				.fa {
					color: var(--secondarycolor);
					margin-right: 10px;
					position: absolute;
					left: 0;
					top: 9px;
				}
			}
		}


		.footer-link {
			color: #888;
		}


	}
}