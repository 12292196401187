@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}



@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


.doc-box {
	li {
		font-size: 14px;
		line-height: 1.42857143;
		color: #333;
		padding: 5px 0;
		font-weight: normal;
	}
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	.form-control {
		letter-spacing: normal;
		text-transform: none;
		font-size: 14px;
		color: var(--grey2);
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

body {
	font-family: var(--defaultfont);
}

h1,
h2,
h3,
h4,
h5,
p,
.form-control {
	font-family: var(--defaultfont);
}

h1,
h2,
h3,
h4,
h5 {
	color: var(--blackcolor);
}

.btn-primary,
.btn-go {
	background-color: var(--primarycolor);
	border-color: var(--primarycolor);
	color: var(--whitecolor);

	&:hover,
	&:focus,
	&:active:focus {
		background-color: var(--secondarycolor);
		border-color: var(--secondarycolor);
	}


}

a {
	color: var(--secondarycolor);
}

.container {
	max-width: 1170px;
	width: 100%;
}

.fa-heart {
	color: var(--primarycolor);
}

.min-row {
	margin-left: -5px;
	margin-right: -5px
}

.min-row .col-sm-6,
.min-row .col-sm-4 {
	padding-left: 5px;
	padding-right: 5px;
}

.form-control {
	background: #fff !important;
	border-radius: 0;
	box-shadow: inherit;
	-o-box-shadow: inherit;
	-webkit-box-shadow: inherit;
}

.flex-row {
	@media (min-width:993px) {
		display: flex;

		.flex-col {
			display: flex;
		}
	}
}

.container-fluid {
	max-width: 1440px;
	width: 100%;
}

.btn,
.form-control {
	border-radius: 0;
	letter-spacing: 2px;
	text-transform: uppercase;
}

@font-face {
	font-family: 'Petra Script EF';
	src: url('PetraScriptEFAlternate.eot');
	src: local('Petra Script EF  Alternate'), local('PetraScriptEFAlternate'),
		url('RESOURCE/fonts/PetraScriptEFAlternate.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/PetraScriptEFAlternate.woff2') format('woff2'),
		url('RESOURCE/fonts/PetraScriptEFAlternate.woff') format('woff'),
		url('RESOURCE/fonts/PetraScriptEFAlternate.ttf') format('truetype'),
		url('RESOURCE/fonts/PetraScriptEFAlternate.svg#PetraScriptEFAlternate') format('svg');
	font-weight: normal;
	font-style: normal;
}

a:focus,
.btn:focus,
:focus {
	outline: 0 !important;
	text-decoration: none !important;
}

.list-unstyled {
	li {
		padding-bottom: 8px;

		i {
			margin-right: 10px;
			color: var(--primarycolor);
		}
	}
}

.mb-30 {
	margin-bottom: 30px !important;
}

.white-bar {
	padding: 16px 0 17px;
}

.unit-view {
	&.facility-view {
		padding-top: 80px;
	}
}

ul.tick-list {
	list-style: none;
	padding: 0;
	margin: 0 0 30px;

	li {
		position: relative;
		padding-left: 30px;
		margin-top: 8px;
		color: var(--blackcolor);

		&:before {
			color: var(--primarycolor);
			content: "\f00c";
			font-family: fontawesome;
			left: 0;
			position: absolute;
			left: 0;
		}
	}
}

.pad60 {
	padding: 60px 0;
}

.widgt-title {
	position: relative;
	padding-left: 35px;
	padding-top: 10px;
	margin-bottom: 15px;

	&:before {
		background: var(--blackcolor);
		width: 18px;
		height: 18px;
		border-radius: 2px;
		content: "\f105";
		font-family: FontAwesome;
		color: #FFF;
		font-size: 0.6em;
		line-height: 1.7em;
		vertical-align: middle;
		text-align: center;
		position: absolute;
		top: 10px;
		left: 0;
	}
}

.btn {
	&.btn-warning {
		background-color: var(--secondarycolor);
		border-color: var(--secondarycolor);
	}
}

.mt-0 {
	margin-top: 0 !important;
}

.min-row {
	margin: 0 -8px;

	.col-md-2,
	.col-xs-4 {
		padding: 0 8px;
	}
}

.contact-form {
	label.control-label {
		display: none;
	}
}

.price-table {
	.special {
		color: var(--primarycolor) !important;
	}
}





.modal {
	padding: 20px;

	.modal-lg {
		@media (min-width: 992px) {
			max-width: 800px;
			width: 100%;
			margin: 0 auto;
		}

		.modal-body {
			&.flex {
				overflow-y: scroll;
				max-height: 680px;
			}
		}
	}
}


.text-black {
	color: var(--blackcolor);
}

.ct-col5 {
	@media (min-width: 768px) {
		width: 50%;
		float: left;
	}

	@media (min-width: 992px) {
		width: 20%;
		float: left;
	}

	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}