@import "setup.css";

.indexpage {

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.banner-slide {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 0;
		min-height: 700px;

		.owl-carousel,
		.owl-stage-outer,
		.owl-stage,
		.owl-item {
			height: 100%;
		}

		@media (max-width:1199px) {
			min-height: 570px;

			.owl-carousel,
			.owl-stage-outer,
			.owl-stage,
			.owl-item {
				height: 100%;
			}
		}





		.item {
			-webkit-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			position: relative;
			//height: 100vh;
			min-height: 700px;
			height: 100%;

			&:before {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(255, 255, 255, .5);
				z-index: 2;
			}

			@media (max-width:1199px) {
				min-height: 570px;
				height: 100%;
			}

			&.slide1 {
				background-image: url(RESOURCE/img/bg-banner-new1.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/bg-banner-new2.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/bg-banner-new3.jpg);
			}

			&.slide4 {
				background-image: url(RESOURCE/img/bg-banner-new4.jpg);
			}
		}

		.logo-tag {
			background-image: url(RESOURCE/img/logo-kk.png);
			position: absolute;
			right: 0;
			bottom: 0;
			width: 403px;
			height: 350px;
			background-size: auto;
			z-index: 2;
			background-repeat: no-repeat;
			opacity: .75;

			@media (max-width:992px) {
				width: 151px;
				background-size: 150px;
				height: 150px;
			}
		}
	}

	.owl-nav {
		button {
			font-size: 65px !important;
			color: var(--white) !important;

			&.owl-prev {
				position: absolute;
				top: 40%;
				left: 15px;
			}

			&.owl-next {
				position: absolute;
				top: 40%;
				font-size: 65px;
				right: 15px;
				color: var(--white);
			}
		}

	}

	.banner-section {
		position: relative;
		min-height: 500px;
		height: 100vh;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		.jumbotron {
			padding-top: 30vh;
			text-align: center;

			@media (max-height:580px) {
				padding-top: 20vh;
			}

			.icon-anchor {
				width: 31px;
				height: 39px;
				display: inline-block;
				background-image: url(RESOURCE/img/icon-anchor.png);
				margin-bottom: 0;
			}

			h1 {
				color: var(--blackcolor);
				display: inline-block;
				/*font-family: helveticaneuethin, sans-serif;*/
			}

			span {
				color: var(--secondarycolor);
				letter-spacing: 2px;
				display: block;
			}

			.border-line {
				background-color: var(--blackcolor);
				width: 200px;
				display: inline-block;
				height: 1px;
				margin-top: 25px;
			}
		}




		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;
				left: 50%;
				top: 0;
				position: absolute;
				transform: translateX(-50%);

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1 {
					font-weight: 100;
					margin: 0 0 5px;
					font-size: 40px;
					display: block;


					@media (max-width:992px) {
						font-size: 24px;
					}
				}

				h2 {
					font-size: 24px;
					font-weight: 400;
					margin: 20px 0 0;

				}

			}

		}


	}



	.searchbar {
		background-color: rgba(255, 255, 255, .5);
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: inherit;
		margin-bottom: 50px;

		padding: 20px 0;

		.row {
			margin-left: -5px;
			margin-right: -5px;
		}

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}



		@media (min-width:993px) {
			max-width: 100%;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

}

@font-face {
	font-family: 'helveticaneuethin';
	src: url('RESOURCE/helveticaneue_thin.woff2') format('woff2'),
		url('RESOURCE/helveticaneue_thin.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}