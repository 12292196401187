@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.list-view {
	.map-col {
		display: none;
	}
}

.searchPageBlock {
	background-color: #ebebeb;
}

.map-view {
	.map-col {
		display: block;

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
			}
		}
	}
}

/*.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}*/

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 0;

		color: #565a5c;


		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 35px 5px 0;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		@media (min-width:993px) {
			&.affix {
				position: relative;
				top: 0 !important;
			}
		}

		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;

		a {
			color: var(--primarycolor);
		}
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 25px 0;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}


	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #fff;
			border: 1px solid var(--blackcolor);
			padding: 3px 10px;
			margin: 0 2px;
			background-color: var(--blackcolor);
			font-size: 11px;
			line-height: 17px;
			min-height: 23px;

			.number {
				margin-left: 5px;
			}
		}

	}

	.unit-btn {
		.btn {
			font-size: 11px;
			padding: 8px 15px;
		}

	}



	.search-bar {
		padding: 320px 0 30px 0;

		&.search-banner {
			background-image: url(RESOURCE/img/banner-search-new.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 500px;

			.form-control {
				margin-bottom: 5px;
			}

			.bg-search-bl {
				background: rgb(255 255 255 / 50%);
				padding: 20px 30px 3px;
			}
		}

		.adv-filt-form {
			padding: 2px 0 0;

			.checkbox {
				margin: 0;
			}
		}

		.btn {
			margin: 2px 0;
			position: relative;
			left: -1px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			//border: 1px solid var(--whitecolor);
			position: relative;
			margin-bottom: 15px;

			@media all and (max-width: 1199px) {
				min-height: 420px;
			}

			@media all and (max-width: 991px) {
				min-height: 395px;
			}

			.alternatives {
				min-height: 120px;
				background-color: #fff;
				padding: 15px 10px;
			}

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;
				background-color: var(--blackcolor);
				padding: 5px 10px;
				color: var(--whitecolor);
				font-weight: bold;

				.offer-price-box {
					.main-price {
						text-decoration: line-through;
						float: left;
						margin-right: 4px;

					}
				}
			}

			.unit-title {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: #7c96ae;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				font-weight: 400;
				font-size: 13px;
				text-transform: uppercase;

				a {
					color: var(--whitecolor);

					&:hover {
						text-decoration: none;
					}
				}
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 0;
					bottom: 0;
					color: var(--primarycolor);
					background: var(--primarycolor);
					color: var(--whitecolor);
					padding: 5px 10px;

					.fa-heart {
						color: var(--whitecolor);
					}
				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				background-color: var(--whitecolor);
				padding: 15px 10px;

				p {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin: 0;
				}

				@media all and (max-width: 767px) {
					min-height: auto;
				}

			}

			.unit-feat {
				background-color: #7c96ae;
				text-align: center;
				padding: 8px 0;


			}
		}
	}


	.map-pane {
		@mixin flex;
		height: 100vh;

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}


		}






	}





}